<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="커뮤니티" src="/images/sub/sv-community.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="커뮤니티" tabActive="도시재생 소식"></client-gnb>
        </template>

        <v-container>
            <router-view name="board" code="press" skin="press" hide-write-button />
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
    },
};
</script>
