var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "커뮤니티",
            "src": "/images/sub/sv-community.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "커뮤니티",
            "tabActive": "도시재생 소식"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('router-view', {
    attrs: {
      "name": "board",
      "code": "press",
      "skin": "press",
      "hide-write-button": ""
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }